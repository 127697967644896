import React, { useState } from 'react'
import AddPage from '../../custom-pages/AddPage';
import * as Yup from 'yup';
import { getAllData } from '../../../apis/customApiHandler';
import { convertToMeridiem } from '@mui/x-date-pickers/internals/utils/time-utils';
import { convertToIndianTimezone, convertToIndianTimezoneAndGetDate } from '../../../utils/helpers';

const TyreAvailabilityDetails = (props) => {

  const [projectStatus, setProjectStatus] = useState([])
  const [tempData, setTempData] = useState(props.data ?? {})

  
  const getProjectStatus = async () => {
    const data = await getAllData("/master/test/getProjectStatus", {});
    setProjectStatus(data?.data)
  }

  useState(()=>{
    getProjectStatus();
  },[])

  const date = new Date();
  
  // const convert = (yourDate)=>{
  //   console.log({yourDate}, new Date(yourDate).getTimezoneOffset())
  //   const offset = new Date(yourDate).getTimezoneOffset()
  //   yourDate = new Date(new Date(yourDate).getTime() - (offset*60*1000))
  //   return yourDate.toISOString().split('T')[0]
  // }

  const convert = (yourDate) => {
    const offset = new Date(yourDate).getTimezoneOffset();
    yourDate = new Date(new Date(yourDate).getTime() - (offset * 60 * 1000));
    return yourDate.toISOString().split('T')[0];
  };

  const today = convert(date);

  console.log("meridian", convert(date), convert(new Date(new Date().getTime() + (10*60*24*60*1000))))
  console.log('tempData',tempData)
    
  const inputsStep4 = [
    {
      label: "Project Status",
      name: "projectStatus",
      required: true,
      type: "select",
      options: projectStatus.map(option => { return { label: option.name, value: option.name } }),
      min: convert(date),
    },
    {
      label: "Project Date",
      name: "projectDate",
      type: "date",
      // min: convert(tempData.expectedDispatch ?? date),
      min:today,
    },
    {
      label: "Expected Tyre Production Date",
      name: "expectedTestTyreProduction",
      type: "date",
      required: true,
      // min: convert(date),
      min: convert(tempData.projectDate ?? date)
    },
     {
      label: "Expected availability at Plant Date",
      name: "expectedAvailabilityPlant",
      type: "date",
      // min: convert(tempData.expectedTestTyreProduction ?? date),
      min: convert(tempData.expectedTestCommencement ?? date),
      required: true,
    },
    {
      label: "Expected Dispatch Date",
      name: "expectedDispatch",
      type: "date",
      min: convert(tempData.expectedTestTyreProduction ?? date),
      onChange: (formik, value)=>{
        console.log({value})
        formik.setFieldValue("expectedDispatch", value);
        formik.setFieldValue("expectedAvailability", value && convert(new Date(new Date(value).getTime() + (60* 10 * 24 * 60 * 1000))));
        formik.setFieldValue("expectedTestCommencement", value && convert(new Date(new Date(value).getTime() + (60* 20 * 24 * 60 * 1000))));
      },
      required: true,
    },

    {
      label: "Expected Availability Date",
      name: "expectedAvailability",
      type: "date",
      // min: convert(tempData.expectedTestTyreProduction ?? date),
      // value: tempData.expectedDispatch && convert(new Date(new Date(tempData.expectedDispatch).getTime() + (60* 10 * 24 * 60 * 1000))),
      disabled: true,
    },
    {
      label: "Expected Test Commencement Date",
      name: "expectedTestCommencement",
      type: "date",
      // value: tempData.expectedDispatch && convert(new Date(new Date(tempData.expectedDispatch).getTime() + (60* 20 * 24 * 60 * 1000))),
      min: convert(date),

      disabled: true,
    },
    {
      label: "Special Comments",
      name: "remarks",
      type: "textarea",
      className: "h-[120px]",
    },
  ];
  const formSchema = Yup.object().shape({

    // coffee: yup.number().when(["isMorning", "isSunday"], {
    //   is: (isMorning, isSunday) => {
    //     //any complex calulation
    //     return isMorning && !isSunday;
    //   },
    //   then: yup.number().required()
    // })
    expectedTestTyreProduction: Yup.date()
      // .transform(function (value, originalValue) {
      //   if (this.isType(value)) {
      //     return value;
      //   }
      //   const result = parse(originalValue, "dd.MM.yyyy", new Date());
      //   return result;
      // })
      // .typeError("please enter a valid date")
      .required()
      .min(new Date(), "Should be more than current date"),

      expectedTestCommencement: Yup.date()
      .min(new Date(), "Should be more than current date"),

      expectedAvailabilityPlant: Yup.date()
      .min(new Date(), "Should be more than current date"),
    
    expectedDispatch: Yup.date()
    .required()
    .min(new Date(), "Should be more than current date"),

    expectedAvailabilityPlant: Yup.date()
    .required()
    .min(new Date(), "Should be more than current date"),
    
  });

  const [disabled, setDisabled] = useState(!(props.data && Object.keys(props.data)?.length))

  // console.log("tyre available", props.data)

  return (
    <AddPage
      index={3}
      handleNextClick={props.handleNextClick}
      data={props.data}
      setData={setTempData}
      totalSteps = {6}
      inputs={inputsStep4}
      formik={props.formik}
      disabled={disabled}
      setDisabled={setDisabled}
      expanded={props.expanded}
      setExpanded={props.setExpanded}
      handleSubmit= {(values)=> (props.handleSubmit("tyreAvailabilityDetails", {tyreAvailabilityDetails: values}, props.index))}
      yupValidationSchema={formSchema}
    />
  )
}

export default TyreAvailabilityDetails