import React, { useCallback, useEffect, useState } from 'react'
import CustomAddPage from '../../../custom-pages/CustomAddModal';
import { createDocument, getAllData } from '../../../../apis/customApiHandler';
import * as Yup from 'yup';
import { createSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const AddTyreDetail = ({ data, handleClick, parentFormik, handleSubmit, totalSteps, setHasErrors, hasErrors, index, ...props }) => {

  const [groove, setGroove] = useState([]);
  const [loadIndex, setLoadIndex] = useState([]);
  const [speedIndex, setSpeedIndex] = useState([]);
  const [plant, setPlant] = useState([]);
  const[tyrePattern,setTyrePattern]=useState([]);
  const [plyRating, setPlyRating] = useState([])
  const [tyreSize, setTyreSize] = useState([])
  const [material, setMaterial] = useState([])
  const [benchmarkMaterial, setBenchmarkMaterial] = useState([])
  const [materialInfo, setMaterialInfo] = useState(null);
  const [tempData, setTempData] = useState(data)

  useEffect(()=>{
    if(data) setTempData(data)
  }, [data])

  // console.log({hasErrors})

  // console.log(tempData)
  
  // const getTyreSize = async () => {
  //   const data = await getAllData("/master/product/getTyreSize", {});
  //   console.log(data?.data)
  //   setTyreSize(data?.data?.map(val=> {return {name: val.description}}))
  // }
  const getGroove = async () => {
    const data = await getAllData("/master/other/getGroove", {});
    setGroove(data?.data)
  }
  const getLoadIndex = async () => {
    const data = await getAllData("/master/other/getLoadIndex", {});
    setLoadIndex(data?.data)
  }
  const getSpeedIndex = async () => {
    const data = await getAllData("/master/other/getSpeedIndex", {});
    setSpeedIndex(data?.data)
  }
  const getPlyRating = async () => {
    const data = await getAllData("/master/other/getPlyRating", {});
    setPlyRating(data?.data)
  }

  const getPlant = async () => {
    const data = await getAllData("/master/other/getPlant", {});
    setPlant(data?.data)
  }
  const getMaterial = async () => {
    const data = await getAllData("/master/product/getMaterial", {
      tyreSize: props.tyreSize
    });
    setMaterial(data?.data?.map(item => {return {label: item.MatDesc, value: item.MatDesc, ...item}}))
  }
  const getBenchmarkMaterial = async () => {
    const data = await getAllData("/master/benchmark/getBenchMarkProduct", {
      tyreSize: props.tyreSize
    });
    setBenchmarkMaterial(data?.data?.filter(item => item.tyreSize?.name?.trim() === props.tyreSize?.trim())?.map(item => {return {label: item.productName, value: item.productName, ...item}}))
  }


  const loadOptions = useCallback(async (searchQuery, loadedOptions, { page }) => {
    const response = await getAllData("/master/product/getMaterial", {
      page: page,
      limit: 100,
      tyreSize: props.tyreSize,
      search: searchQuery.toUpperCase(),
    });
    return {
      options: response.data,
      hasMore: response.data?.length
        ? response.currentPage !== response.totalPage
        : false,
        additional: {
          page: page + 1,
        },
    };
  }, []);

  const onChange = (formik, value)=>{
    console.log('clicked-->',tempData?.testTyre,'value',value);
    
    setMaterialInfo(value)
    formik.setFieldValue("material", value.MatDesc);
    formik.setFieldValue("NSD", value.originalNSD);
    if (tempData?.testTyre === "JK") {
      setTyrePattern(value.MatDesc);
    } else if (tempData?.testTyre === "Benchmark") {
      setTyrePattern(value.productName);
    }
    formik.setFieldValue("tyrePattern", tempData?.testTyre === "JK" ? value.MatDesc : value.productName);
  }

  console.log('tyrePattern-->',tyrePattern);
  


  useEffect(() => {
    getGroove();
    getLoadIndex();
    getSpeedIndex();
    getPlant();
    getPlyRating();
    // getTyreSize();
    getMaterial();
    getBenchmarkMaterial();
  }, [])

    const [initialValues, setInitialValues] = React.useState({
      ...data,
      tyreSizedescription: props.tyreSize,
      plant: data?.plant ?? props.plant,
      tyrePattern: data?.tyrePattern || '',
    });

    useEffect(()=>{
      if (tempData?.groove) {
        let nsd = 0;
        for (var i = 1; i <= parseInt(tempData.groove); i++) {
          nsd += parseFloat(tempData[`G${i}`]);
        }
        nsd /= parseInt(tempData.groove);
        // formik.setFieldValue("NSD", parseFloat(nsd).toFixed(2));
        setInitialValues({
          ...tempData,
          NSD: parseFloat(nsd).toFixed(2)
        })
      }
    }, [tempData?.G1, tempData?.G2, tempData?.G3, tempData?.G4, tempData?.G5, tempData?.G6])
    
  
    const inputFields = [
      {
        name: "testTyre",
        label: "Test Type",
        type: "select",
        required: true,
        className: "w-full col-span-2",
        options: [
          { label: "JK Tyre", value: "JK" },
          { label: "Benchmark", value: "Benchmark" },
        ],
      },
      {
        name: "tyreSizedescription",
        label: "Tyre size Description",
        disabled: props.tyreSize ? true : false,
        // value: materialInfo && materialInfo?.tyreSize
        value: props.tyreSize,
      },
      {
        name: "group",
        label: "Group",
        type: "text",
        required: true,
      },
      {
        name: "groupDescription",
        label: "Group Description",
        type: "text",
        required: true,
      },
      {
        name: "material",
        label: "Material",
        required: true,
        type: "select",
          // tempData?.testTyre !== "JK"
          //   ? "text"
          //   : // "async-select",
          //     "select",
        // loadOptions: loadOptions,
        // options:  tempData?.testTyre === "JK"
        // ? material
        // : benchmarkMaterial,
         options: tempData?.testTyre === "JK" ? material : benchmarkMaterial,
        required: true,
        optionField: "materialNo",
        className: "w-full col-span-2 font-normal",
        optionField: "MatDesc",
        ...(tempData?.testTyre === "JK" && { onChange: onChange }),
      },
      {
        name: "plyRating",
        label: "Ply Rating",
        type: "select",
        className: "w-full col-span-2",
        // value: materialInfo?.plyRating,
        options: plyRating.map((option) => {
          return { label: option.name, value: option.name };
        }),
        required: true,
      },
      {
        name: "loadIndex",
        label: "Load Index",
        type: "select",
        className: "w-full col-span-2",
        options: loadIndex.map((option) => {
          return { label: option.name, value: option.name };
        }),
        required: true,
      },
      {
        name: "speedRating",
        label: "Speed Rating",
        type: "select",
        className: "w-full col-span-2",
        options: speedIndex.map((option) => {
          return { label: option.name, value: option.name };
        }),
        required: true,
      },
      {
        name: "ftQuantity",
        label: "FT Quantity",
        type: "number",
        onChange: (formik, value) => {
          // console.log(formik, value);
          const { ptQuantity } = formik.values;
          formik.setFieldValue("ftQuantity", parseInt(value));
          formik.setFieldValue(
            "noOfTyres",
            (ptQuantity ?? 0) + parseInt(value)
          );
        },
      },
      {
        name: "ptQuantity",
        label: "PT Quantity",
        type: "number",
        onChange: (formik, value) => {
          // console.log({ formik, value });
          // console.log({event: e})
          const { ftQuantity } = formik.values;
          formik.setFieldValue("ptQuantity", parseInt(value));
          formik.setFieldValue(
            "noOfTyres",
            (ftQuantity ?? 0) + parseInt(value)
          );
        },
      },
      {
        name: "noOfTyres",
        label: "No of Tyres (FT / PT)",
        type: "number",
        required: true,
        disabled: true,
        // disabled: tempData?.ptQuantity || tempData?.ftQuantity,
        // value: ((tempData?.ptQuantity ?? 0) + (tempData?.ftQuantity ?? 0)),
      },

      {
        name: "discount",
        label: "Discount % (FT)",
        type: "text",
        max: 100,
      },

      {
        name: "plant",
        label: "Plant",
        type: "select",
        // value: props.plant,
        disabled: tempData?.testTyre === "JK" ? true : false,
        className: "w-full col-span-2",
        options: plant.map((option) => {
          return { label: option.name, value: option.name };
        }),
      },
      {
        name: "inflatedInfo",
        label: "Inflated Info",
        type: "checkbox",
        className: "pt-2",
      },

      {
        name: "overallDiameter",
        label: "Overall Diameter",
        type: "number",
        required: true,
      },
      {
        name: "sectionWidth",
        label: "Section Width(mm)",
        type: "number",
        required: true,
      },

      {
        name: "NSD",
        label: "NSD",
        type: "number",
        onChange: (formik, value)=>{
          const NSD = value;
          const grooveValue = parseInt(formik.values.groove);
          if (grooveValue >= 1) formik.setFieldValue("G1", parseFloat(NSD));
          else formik.setFieldValue("G1", "");
          if (grooveValue >= 2) formik.setFieldValue("G2", parseFloat(NSD));
          else formik.setFieldValue("G2", "");
          if (grooveValue >= 3) formik.setFieldValue("G3", parseFloat(NSD));
          else formik.setFieldValue("G3", "");
          if (grooveValue >= 4) formik.setFieldValue("G4", parseFloat(NSD));
          else formik.setFieldValue("G4", "");
          if (grooveValue >= 5) formik.setFieldValue("G5", parseFloat(NSD));
          else formik.setFieldValue("G5", "");
          if (grooveValue >= 6) formik.setFieldValue("G6", parseFloat(NSD));
          else formik.setFieldValue("G6", "");

          formik.setFieldValue("NSD", value)
        },
        required: true,
      },
      {
        name: "groove",
        label: "No of Grooves",
        type: "select",
        required: true,
        className: "w-full col-span-2",
        // disabled: !tempData?.NSD,
        options: groove.map((option) => {
          return { label: option.name, value: option.name };
        }),
        onChange: (formik, value) => {
          // console.log({ formik, value });
          // console.log({event: e})
          const { NSD } = formik.values;
          const grooveValue = parseInt(value.value);
          if (grooveValue >= 1) formik.setFieldValue("G1", parseFloat(NSD));
          else formik.setFieldValue("G1", "");
          if (grooveValue >= 2) formik.setFieldValue("G2", parseFloat(NSD));
          else formik.setFieldValue("G2", "");
          if (grooveValue >= 3) formik.setFieldValue("G3", parseFloat(NSD));
          else formik.setFieldValue("G3", "");
          if (grooveValue >= 4) formik.setFieldValue("G4", parseFloat(NSD));
          else formik.setFieldValue("G4", "");
          if (grooveValue >= 5) formik.setFieldValue("G5", parseFloat(NSD));
          else formik.setFieldValue("G5", "");
          if (grooveValue >= 6) formik.setFieldValue("G6", parseFloat(NSD));
          else formik.setFieldValue("G6", "");
          formik.setFieldValue("groove", value.value);
        },
      },
      {
        name: "G1",
        label: "G1",
        className: "",
        type: "number",
        halfCol: true,
        onChange: (formik, value) => {
          formik.setFieldValue("G1", value);
          
          const grooves = parseInt(tempData?.groove)
          const averageNSD =
            parseInt((
              (parseInt(value) +
              (grooves >= 2 ? formik.values.G2 : 0) +
              (grooves >= 3 ? formik.values.G3 : 0) +
              (grooves >= 4 ? formik.values.G4 : 0) +
              (grooves >= 5 ? formik.values.G5 : 0) +
              (grooves >= 6 ? formik.values.G6 : 0)) 
              / parseInt(grooves))*10)
              /10;
          
            
          formik.setFieldValue("NSD", averageNSD)
        },
        disabled: !tempData?.groove || tempData?.groove < 1,
      },
      {
        name: "G2",
        label: "G2",
        className: "",
        type: "number",
        halfCol: true,
        onChange: (formik, value) => {
          formik.setFieldValue("G2", value);
          
          const grooves = parseInt(tempData?.groove)
          const averageNSD =
            parseInt((
              (formik.values?.G1 +
              (grooves >= 2 ? parseInt(value) : 0) +
              (grooves >= 3 ? formik.values.G3 : 0) +
              (grooves >= 4 ? formik.values.G4 : 0) +
              (grooves >= 5 ? formik.values.G5 : 0) +
              (grooves >= 6 ? formik.values.G6 : 0)) 
              / parseInt(grooves))*10)
              /10;
          
            
          formik.setFieldValue("NSD", averageNSD)
        },
        disabled: !tempData?.groove || tempData?.groove < 2,
      },
      {
        name: "G3",
        label: "G3",
        className: "",
        type: "number",
        halfCol: true,
        onChange: (formik, value) => {
          formik.setFieldValue("G3", value);
          
          const grooves = parseInt(tempData?.groove)
          const averageNSD =
            parseInt((
              (formik.values?.G1 +
              (grooves >= 2 ? formik.values.G2 : 0) +
              (grooves >= 3 ? parseInt(value) : 0) +
              (grooves >= 4 ? formik.values.G4 : 0) +
              (grooves >= 5 ? formik.values.G5 : 0) +
              (grooves >= 6 ? formik.values.G6 : 0)) 
              / parseInt(grooves))*10)
              /10;
          
            
          formik.setFieldValue("NSD", averageNSD)
        },
        disabled: !tempData?.groove || tempData?.groove < 3,
      },
      {
        name: "G4",
        label: "G4",
        className: "",
        type: "number",
        halfCol: true,
        onChange: (formik, value) => {
          formik.setFieldValue("G4", value);
          
          const grooves = parseInt(tempData?.groove)
          const averageNSD =
            parseInt((
              (formik.values?.G1 +
              (grooves >= 2 ? formik.values.G2 : 0) +
              (grooves >= 3 ? formik.values.G3 : 0) +
              (grooves >= 4 ? parseInt(value) : 0) +
              (grooves >= 5 ? formik.values.G5 : 0) +
              (grooves >= 6 ? formik.values.G6 : 0)) 
              / parseInt(grooves))*10)
              /10;
          
            
          formik.setFieldValue("NSD", averageNSD)
        },
        disabled: !tempData?.groove || tempData?.groove < 4,
      },
      {
        name: "G5",
        label: "G5",
        className: "w-full",
        halfCol: true,
        type: "number",
        onChange: (formik, value) => {
          formik.setFieldValue("G5", value);
          
          const grooves = parseInt(tempData?.groove)
          const averageNSD =
            parseInt((
              (formik.values?.G1 +
              (grooves >= 2 ? formik.values.G2 : 0) +
              (grooves >= 3 ? formik.values.G3 : 0) +
              (grooves >= 4 ? formik.values.G4 : 0) +
              (grooves >= 5 ? parseInt(value) : 0) +
              (grooves >= 6 ? formik.values.G6 : 0)) 
              / parseInt(grooves))*10)
              /10;
          
            
          formik.setFieldValue("NSD", averageNSD)
        },
        disabled: !tempData?.groove || tempData?.groove < 5,
      },
      {
        name: "G6",
        label: "G6",
        className: "",
        halfCol: true,
        type: "number",
        onChange: (formik, value) => {
          formik.setFieldValue("G6", value);
          
          const grooves = parseInt(tempData?.groove)
          const averageNSD =
            parseInt((
              (formik.values?.G1 +
              (grooves >= 2 ? formik.values.G2 : 0) +
              (grooves >= 3 ? formik.values.G3 : 0) +
              (grooves >= 4 ? formik.values.G4 : 0) +
              (grooves >= 5 ? formik.values.G5 : 0) +
              (grooves >= 6 ? parseInt(value) : 0)) 
              / parseInt(grooves))*10)
              /10;
          
            
          formik.setFieldValue("NSD", averageNSD)
        },
        disabled: !tempData?.groove || tempData?.groove < 6,
      },

      {
        name: "treadArc",
        label: "Tread Arc Width(mm)",
        type: "number",
        required: true,
      },

      {
        name: "weight",
        label: "Tyre Weight",
        type: "number",
      },
      {
        name: "treadWidth",
        label: "Tread Width(mm)",
        type: "number",
        required: true,
      },
      {
        name: "stencilFrom",
        label: "Stencil From",
        type: "text",
      },
      {
        name: "stencilTo",
        label: "Stencil To",
        type: "text",
      },
      {
        name: "comments",
        label: "Additional Comments",
        type: "text",
      },
    ];

    useEffect(()=>{
      // 
    }, [tempData])

    const formSchema = Yup.object().shape({

      ftQuantity: Yup.number().min(0, "FT Quantity cannot be <0"),
      ptQuantity: Yup.number().min(0, "PT Quantity cannot be <0"),
      treadWidth: Yup.number().min(0, "Tread Arc cannot be <0"),
      treadArc: Yup.number().min(0, "Tread Arc Width cannot be <0"),
      sectionWidth: Yup.number().min(0, "Section Arc Width cannot be <0"),
      overallDiameter: Yup.number().min(0, "overallDiameter cannot be <0"),
      NSD: Yup.number().min(0, "NSD cannot be <0").max(30, "NSD cannot be >30"),
      ...tempData?.groove > 1 && {G1: Yup.number().min(0, "G1 cannot be <0").max(30, "G1 cannot be >30")},
      ...tempData?.groove > 1 && {G2: Yup.number().min(0, "G2 cannot be <0").max(30, "G2 cannot be >30")},
      ...tempData?.groove > 2 && {G3: Yup.number().min(0, "G3 cannot be <0").max(30, "G3 cannot be >30")},
      ...tempData?.groove > 3 && {G4: Yup.number().min(0, "G4 cannot be <0").max(30, "G4 cannot be >30")},
      ...tempData?.groove > 4 && {G5: Yup.number().min(0, "G5 cannot be <0").max(30, "G5 cannot be >30")},
      ...tempData?.groove > 5 && {G6: Yup.number().min(0, "G6 cannot be <0").max(30, "G6 cannot be >30")},
      groove: Yup.number().required(),
      weight: Yup.number().min(0, "Weight cannot be <0"),
      ftQuantity: Yup.number().min(0, "FT Quantity cannot be <0"),
      discount: Yup.number().min(0, "Discont should be >= 0%").max(100, "Discount should be <=100%"),
      // noOfTyres: Yup.number().min(0, "Required, Please add FT or PT Quantity"),
      group: Yup.string()
        .test('non-duplicate-groups', 'Group should be unique', function(value) {
          const { group } = this.parent;
          let findIndex = props.tyreDetails?.findIndex(item=> item.tyre.group === group);
          console.log("GROUP TEST", )
          return findIndex === undefined || [-1, index].includes(findIndex)
        }),
      noOfTyres: Yup.number()
        .test('sum-equals-noOfTyres', 'The sum of FT Quantity and PT Quantity should equal No of Tyres', function(value) {
          const { ftQuantity, ptQuantity, noOfTyres } = this.parent;
          console.log({ftQuantity, ptQuantity, value})
          console.log(this.parent)
          // Ensure that noOfTyres is equal to the sum of ftQuantity and ptQuantity
          return ((ftQuantity ?? 0) + (ptQuantity ?? 0)) === value;
        }),
      stencilTo: Yup.string().test('conditional-validati', 'Invalid value for from', function(value) {
          const stencilFrom = this.parent.stencilFrom;
          console.log({stencilFrom, value})
          if (stencilFrom == value) {
            return Yup.string().required("Stencil From and To can't be same");
          }
          return true;
        }),
    });
    


    return (
      <CustomAddPage
        pageTitle={data ? "Update Tyre Detail" : "Add Tyre Detail"}
        // action={!data ? "create" : "update"}
        updateId={data?._id}
        queryKey="permission"
        inputFields={inputFields}
        data={initialValues}
        setData={setTempData}
        handleClick={handleClick}
        handleSubmit={handleSubmit}
        yupValidationSchema={formSchema}
        totalSteps={totalSteps}
        cname= "min-w-4xl"
        helperCall={async (data)=>{
          // stencilNo, materialDesc
          console.log(data)
          if(data.testTyre !== "JK") return true;
          // if(data.stencilFrom === data.stencilTo) return false;
          console.log("HELPER CALLED")
          
          const validate1 = data.stencilFrom?.length > 5 ? await createDocument("/validate/stencil?", {
            stencilNo: data.stencilFrom,
            materialDesc: data.material,
          }): true;
          const validate2 = data.stencilTo?.length > 5 ? await createDocument("/validate/stencil?", {
            stencilNo: data.stencilTo,
            materialDesc: data.material,
          }): true;

          if(!(validate1 && validate2)){
            setHasErrors({...hasErrors, index: true})
          }else{
            if(!(validate1 && validate2)){
              setHasErrors({...hasErrors, index: false})
            }
          }
          // console.log({validate1}, validate1 && validate2)

          if(validate1 && validate2) return true 
          else throw new Error("RRR")
        }}
      />
    );
  };

export default AddTyreDetail